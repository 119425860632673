.btn {
  @apply inline-block box-content rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2;
  &.btn-primary {
    @apply bg-slate-800 text-white hover:bg-slate-700 focus-visible:outline-white/50 active:text-slate-400;
  }
  &.btn-secondary {
    @apply bg-sky-300 text-slate-900 hover:bg-sky-200 focus-visible:outline-sky-300/50 active:bg-sky-500;
  }
  &.btn-error {
    @apply bg-red-500 text-white hover:bg-red-400 focus-visible:outline-red-500/50 active:bg-red-600;
  }
}
