@tailwind base;

:root {
  --primary: 255 255 255;
  --secondary: rgb(14 165 233);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) rgba(0, 0, 0, 0);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid rgba(0, 0, 0, 0);
}

@tailwind components;
@tailwind utilities;

@import 'atoms/buttons';
@import 'atoms/navigation/main_nav_item';

.prose {
  .responsive-object {
    @apply relative;
    iframe,
    video {
      @apply absolute w-full h-full;
    }
  }
  .richtext-image {
    &.full-width {
      @apply block mx-auto w-full h-auto;
    }
    &.left {
      @apply float-left mr-4;
    }
    &.right {
      @apply float-right ml-4;
    }
  }
}

.input {
  @apply max-w-full block w-full rounded-md border-0 py-1.5 text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6;
}

.label {
  @apply block text-lg font-semibold leading-6 text-slate-700 mb-4;
}

.input-group {
  @apply mb-6;
}

.input-error {
  @apply block text-sm text-red-600;
}
